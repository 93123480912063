import React, { useEffect, useState } from "react";
import Header from "../layout/header";
import SideBar from "./sidebar";
import { useLocation } from "react-router-dom";
import { Slide } from "react-reveal";

const MainLayout = (props) => {
  const location = useLocation();
  const isAuthLayout =
    location.pathname !== "/signin" &&
    location.pathname !== "/signin/" &&
    location.pathname !== "/signup" &&
    location.pathname !== "/signup/" &&
    location.pathname !== "/reset-password" &&
    location.pathname !== "/confirm_password/" && 
    location.pathname !== "/mid_winter_registration_form"

  const [sidebar, setSideBar] = useState(false);


  const [userData, setUserData] = useState(null)


  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUserData(user)
  }, [])
  return (
    <div className="flex">
      {isAuthLayout && (
        <div
          className={`${sidebar ? "w-[15%]" : "w-0"
            } transition-all duration-1000 fixed  top-0`}
        >
          <Slide when={sidebar} duration={1000}>
            <SideBar {...{ setSideBar, sidebar, userData }} />
          </Slide>
        </div>
      )}
      <div
        className={`${isAuthLayout && sidebar ? "w-[85%]" : "w-full"
          } transition-all duration-1000 ml-auto`}
      >
        {isAuthLayout && <Header {...{ setSideBar, sidebar, userData }} />}
        <div className={`${isAuthLayout && 'px-5 md:px-10'} `}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
